import Cookie from 'js-cookie'
export function checkTokenBeforeRouteEnter(to, from, next) {
  // 如果是开发环境,则直接执行 next() 跳过检查
  if (process.env.NODE_ENV === 'development') {
    next();
    return;
  }

  const token = Cookie.get('myCookie')
  if (!token) {
    const confirmed = confirm('token为空，点击确定前往授权页面授权', '授权提醒')
    if (confirmed) {
      window.location.href = process.env.VUE_APP_MAPI_AUTH_URL
    }
  } else {
    console.log('token:' + token)
    next()
  }
}
